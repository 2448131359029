import { gql, GraphQLClient } from "graphql-request";
import fetchWrapper from "../utils/fetch/fetchWrapper";

const fetchDealersGraphQl = async (token) => {
  let endpoint = process.env.REACT_APP_AMPLIFY_STAGING_ENDPOINT;
  let apiKey = process.env.REACT_APP_AMPLIFY_STAGING_API_KEY;

  if (process.env.REACT_APP_ENVIRONMENT === "production") {
    endpoint = process.env.REACT_APP_AMPLIFY_PRODUCTION_ENDPOINT;
    apiKey = process.env.REACT_APP_AMPLIFY_PRODUCTION_API_KEY;
  }

  const graphQLClient = new GraphQLClient(endpoint, {
    headers: {
      "X-Api-Key": apiKey,
    },
  });

  let query = gql`
    {
      listEVDealersTypes {
        items {
          dealerId
          dealershipHandle
          type,
          oem
        }
        nextToken
      }
    }
  `;

  if (token) {
    query = gql`
          {
            listEVDealersTypes(nextToken: "${token}") {
              items {
                dealerId
                dealershipHandle,
                type,
                oem
              }
              nextToken
            }
          }
        `;
  }

  const data = await graphQLClient.request(query);
  return data;
};

export const fetchDealersType = async (params) => {
  const dealers = [];
  let token;
  while (token !== null) {
    let data = await fetchDealersGraphQl(token);

    dealers.push(...data.listEVDealersTypes.items);
    token = data.listEVDealersTypes.nextToken;
  }

  return dealers;
};

const fetchDealers = async (params) => {
  // Remove null params
  Object.keys(params).forEach(
    (key) => params[key] == null && delete params[key]
  );

  let url = new URL(`${process.env.REACT_APP_API_HOST}/dealers`);

  let searchParams = new URLSearchParams(params);

  url.search = searchParams;

  const json = await fetchWrapper(url, {
    method: 'GET',
  });

  return json.dealers;
};

export default fetchDealers;
