// fetchWrapper.js

const apiKey =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_PRODUCTION_TOKEN
    : process.env.REACT_APP_STAGING_TOKEN;

const fetchWrapper = async (url, options = {}) => {
  // Add default headers (like Authorization) to every request
  const headers = {
    ...options.headers,
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-Api-Key': apiKey,
  };

  // Merge headers with other options like method, body, etc.
  const finalOptions = {
    ...options,
    headers,
  };

  // Make the actual fetch request
  const response = await window.fetch(url, finalOptions);

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
};

export default fetchWrapper;
